export const UserRole = {
  Admin: {id: 2 , currency: 'MYR' , country:'malaysia'},
  Editor: 1,
};

/* 
Menu Types:
"menu-default", "menu-sub-hidden", "menu-hidden"
*/
export const defaultMenuType = 'menu-default';

export const subHiddenBreakpoint = 1440;
export const menuHiddenBreakpoint = 768;
export const defaultLocale = 'en';
export const localeOptions = [
  { id: 'en', name: 'English - LTR', direction: 'ltr' },
  { id: 'es', name: 'Español', direction: 'ltr' },
  { id: 'enrtl', name: 'English - RTL', direction: 'rtl' },
];
// export const API_URL = 'http://localhost:63747/api'
// export const API_URL = 'https://athididevapi.signatech.com/api';
export const API_URL = 'https://athidiapi.signatech.com/api';

export const Country = [
  {label : 'Malaysia' , value: 'MYR' , key : 0},
  {label : 'India' , value: 'INR' , key : 1},
  {label : 'United States' , value: 'USD' , key : 2},
]

export const Active = [
  {label : 'Active' , value: 1, key : 0},
  {label : 'InActive' , value: 0 , key : 1},
]

export const firebaseConfig = {
  apiKey: 'AIzaSyBBksq-Asxq2M4Ot-75X19IyrEYJqNBPcg',
  authDomain: 'gogo-react-login.firebaseapp.com',
  databaseURL: 'https://gogo-react-login.firebaseio.com',
  projectId: 'gogo-react-login',
  storageBucket: 'gogo-react-login.appspot.com',
  messagingSenderId: '216495999563',
};

export const currentUser = {
  id: 1,
  title: 'Sarah Kortney',
  img: '/assets/img/profiles/l-1.jpg',
  date: 'Last seen today 15:24',
  role: UserRole.Admin.id,
};

export const adminRoot = '/app';
export const buyUrl = 'https://signatech.com';
export const searchPath = `${adminRoot}/pages/miscellaneous/search`;
export const servicePath = 'https://api.coloredstrategies.com';

export const themeColorStorageKey = '__theme_selected_color';
export const isMultiColorActive = false;
export const defaultColor = 'light.greenmoss';
export const isDarkSwitchActive = true;
export const defaultDirection = 'ltr';
export const themeRadiusStorageKey = '__theme_radius';
export const isAuthGuardActive = false;
export const colors = [
  'bluenavy',
  'blueyale',
  'blueolympic',
  'greenmoss',
  'greenlime',
  'purplemonster',
  'orangecarrot',
  'redruby',
  'yellowgranola',
  'greysteel',
];
export const userRole = [
  { label: 'Customer', value: '0', key: 2 },
  { label: 'Chef', value: '4', key: 2 },
  { label: 'Manager', value: '3', key: 2 },
  { label: 'Admin', value: '2', key: 1 },
  { label: 'Super Admin', value: '1', key: 0 },
];
export const optionsArray = [
  { label: 'Veg', value: 'veg', key: 0 },
  { label: 'Vegan', value: 'vegan', key: 1 },
  { label: 'Non Veg', value: 'non-veg', key: 2 },
];
export const statusArray = [
  { label: 'Draft', value: '7', key: 0 },
  { label: 'Publish', value: '8', key: 1 },
];
export const currencyArray = [
  { label: 'Rupees INR', value: 'INR', key: 0 },
  { label: 'USA Dollar', value: 'USD', key: 1 },
  { label: 'Malaysian Ringgit MYR', value: 'MYR', key: 2 }
];
export const statusName = {
  1: 'Pending',
  2: 'Processing',
  3: 'Completed',
  4: 'Rejected',
  5: 'Failed',
  6: 'Cancelled',
};
// Order Status id - Status name
export const orderStatusReferences = [
  { id: 0, des: 'Zero', color: 'blue' },
  { id: 1, des: 'Pending', color: '#f29339' },
  { id: 2, des: 'Processing', color: '#f29339' },
  { id: 3, des: 'Completed', color: 'green' },
  { id: 4, des: 'Rejected', color: 'red' },
  { id: 5, des: 'Failed', color: '#D9512C' },
  { id: 6, des: 'Cancelled', color: '#6D6D64' },
  { id: 7, des: 'Drafted', color: 'sencondary' },
  { id: 8, des: 'Published', color: 'success' },
  { id: 9, des: 'Completed/Closed', color: 'primary' },
];
export const dropDownStatus = [
  { value: '', label: 'All' },
  { value: '1', label: 'Pending' },
  { value: '2', label: 'Processing' },
  { value: '3', label: 'Completed' },
  { value: '4', label: 'Rejected' },
  { value: '5', label: 'Failed' },
  { value: '6', label: 'Cancelled' },
];
export const dropDownPaymentTypes = [
  { value: '', label: 'All' },
  { value: 'COD', label: 'COD' },
  { value: 'Online Transfer', label: 'Online Transfer' },
];
export const pickStatus = (status) => {
  switch (status) {
    case 0:
      return 'warning';
    case 1:
      return 'warning';
    case 2:
      return 'info';
    case 3:
      return 'success';
    case 4:
      return 'theme-5';
    case 5:
      return 'info';
    case 6:
      return 'danger';
    default:
      return 'info';
  }
};
